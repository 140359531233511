.dynamic-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 45px;
  -webkit-transition: all 0.45s ease !important;
  -o-transition: all 0.45s ease !important;
  -moz-transition: all 0.45s ease !important;
  transition: all 0.45s ease !important;
}
.image-popup-normal {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 0.45s ease all;
    z-index: 1;
  }
  &::before {
    z-index: 2;
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MDguOTIgNDA5LjAyIj48ZGVmcz48c3R5bGU+LmR7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iZCIgZD0iTTEwMi4yLDBWMjUuNDNINDQuODZjMzkuMzIsMzkuMzMsNzguNTQsNzguNTYsMTE3LjUxLDExNy41NC02LjcsNi42Ny0xMi42LDEyLjUzLTE4LjU5LDE4LjQ5QzEwNS4wMSwxMjIuNjgsNjUuNzcsODMuNDIsMjUuNzUsNDMuMzl2NTguODNIMFY5LjU4QzEuOTEsNS4xMSw1LjExLDEuOTEsOS41OCwwSDEwMi4yWm0yMDQuNCwwVjI1Ljc3aDU3LjY5bC41MSwuOTNjLTM5LjI1LDM5LjI2LTc4LjUsNzguNTItMTE3LjU1LDExNy41Nyw1LjgsNS44MiwxMS42MSwxMS42NSwxNy44MSwxNy44NywzOS4xMy0zOS4xNCw3OC4zOC03OC40LDExOC4yOC0xMTguMzF2NTguMTdoMjUuNDZ2LTQuNTFjMC0yNi44OC0uMTgtNTMuNzYsLjExLTgwLjYzLC4wOS03LjkyLTIuMjItMTMuNjctOS43LTE2Ljg2aC05Mi42MlpNMCwzOTkuMzJjMy4xOSw3LjQ4LDguOTUsOS43OCwxNi44Nyw5LjcsMjcuMDEtLjMsNTQuMDItLjExLDgxLjAzLS4xMWg0LjA3di0yNS42NEg0My42M2MzOS45My0zOS45NCw3OS4xOS03OS4yLDExOC4zMS0xMTguMzMtNi4yMi02LjA5LTEyLjE4LTExLjkzLTE4LjcxLTE4LjMyLTM5LjA2LDM4Ljk0LTc4LjM4LDc4LjEzLTExNy43OCwxMTcuNDF2LTU3LjMzSDB2OTIuNjRaTTI2NS4yOSwyNDYuODdjLTYuMzMsNi4zNC0xMi4yMywxMi4yNC0xOC44NywxOC44OSwzOS4wMSwzOS4wMiw3OC4yNiw3OC4yOCwxMTcuNjcsMTE3LjdoLTU3LjJ2MjUuNDVoMy44NWMyNy42NywwLDU1LjM0LDAsODMuMDEsMCwxMC4yNSwwLDE1LjA0LTQuODYsMTUuMDUtMTUuMjIsMC0yNy41NCwwLTU1LjA5LDAtODIuNjN2LTQuMTZoLTI1LjU4djU3Ljk2Yy0zOS43My0zOS43NC03OC45OC03OS0xMTcuOTQtMTE3Ljk3WiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center center;
    transition: 0.45s ease all;
    opacity: 0;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    &::before {
      opacity: 1;
      translate: translate(-50%, -50%);
    }
  }
}
@media screen and (max-width: 1280px) {
  .image-popup-normal::before {
    display: none;
  }
}
.full-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}
.full-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.before-full:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.35s ease all;
}
.after-full:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.35s ease all;
}
.backface-visible {
  backface-visibility: visible;
}
.backface-hidden {
  backface-visibility: Â hidden;
}
.image-zoom img {
  transition: all 0.45s ease;
}

.image-zoom:hover img {
  transform: scale(1.1);
}
.form-el {
  position: relative;
}
.animate-carousel > .swiper-wrapper > .swiper-slide > * {
  opacity: 0;
  pointer-events: none;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
  -o-transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
  transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
}
.animate-carousel > .swiper-wrapper > .swiper-slide-visible > * {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.swiper-pagination-bullet {
  width: 123px !important;
  height: 2px !important;
  background: #fff !important;
  opacity: 0.5;
  border-radius: 0 !important;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s;
  }
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  &::before {
    flex-shrink: 0;
    background-color: #b08538;
    animation: slide-progress 10s cubic-bezier(0.3, 0, 0.3, 1) forwards;
  }
}

@keyframes slide-progress {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
.project-box .image .box {
  -webkit-transform: translateZ(0) translateZ(0);
  -moz-transform: translateZ(0) translateZ(0);
  transform: translateZ(0) translateZ(0);
}
