@font-face {
    font-family: 'goldlife';
    src: url('../font/icon/goldlife.eot');
    src: url('../font/icon/goldlife.eot?#iefix') format('embedded-opentype'),
         url('../font/icon/goldlife.woff') format('woff'),
         url('../font/icon/goldlife.ttf') format('truetype'),
         url('../font/icon/goldlife.svg#goldlife') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'goldlife';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-arrow-left:before{content:'\0041';}
.icon-arrow-right:before{content:'\0042';}
.icon-arrow-top:before{content:'\0043';}
.icon-facebook:before{content:'\0044';}
.icon-linkedin:before{content:'\0045';}
.icon-location:before{content:'\0046';}
.icon-mail:before{content:'\0047';}
.icon-phone:before{content:'\0048';}
.icon-rectangle:before{content:'\0049';}
.icon-youtube:before{content:'\004a';}
.icon-x:before{content:'\004b';}